import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Floater from 'components/floating-grid'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/implements/utility-tractors/loader-attachments/4-in-1-buckets/',
    imageId: 'fourInOneBucketsImage',
    name: '4-in-1 Buckets',
    alt: '4-in-1 Buckets',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/bale-forks/',
    imageId: 'baleForksImage',
    name: 'Bale Forks',
    alt: 'Bale Forks',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/bale-huggers/',
    imageId: 'baleHuggersImage',
    name: 'Bale Huggers',
    alt: 'Bale Huggers',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/bale-spears/',
    imageId: 'baleSpearsImage',
    name: 'Bale Spears',
    alt: 'Bale Spears',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/debris-blowers/',
    imageId: 'debrisBlowersImage',
    name: 'Debris Blowers',
    alt: 'Debris Blowers',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/debris-grapples/',
    imageId: 'debrisGrapplesImage',
    name: 'Debris Grapples',
    alt: 'Debris Grapples',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/free-stall-scrapers/',
    imageId: 'freeStallScrapersImage',
    name: 'Free-Stall Scrapers',
    alt: 'Free-Stall Scrapers',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/hydraulic-power-packs/',
    imageId: 'hydraulicPowerPacksImage',
    name: 'Hydraulic Power Packs',
    alt: 'Hydraulic Power Packs',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/large-square-bale-squeezers/',
    imageId: 'largeBaleSqueezersImage',
    name: 'Large Square Bale Squeezers',
    alt: 'Large Square Bale Squeezers',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/manure-forks/',
    imageId: 'manureForksImage',
    name: 'Manure Forks',
    alt: 'Manure Forks',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/manure-forks-with-grapple/',
    imageId: 'manureForksWithGrappleImage',
    name: 'Manure Forks with Grapple',
    alt: 'Manure Forks with Grapple',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/mechanical-grapples/',
    imageId: 'mechanicalGrapplesImage',
    name: 'Mechanical Grapples',
    alt: 'Mechanical Grapples',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/pallet-forks/',
    imageId: 'palletForksImage',
    name: 'Pallet Forks',
    alt: 'Pallet Forks',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/pallet-fork-grapples/',
    imageId: 'palletForkGrapplesImage',
    name: 'Pallet Fork Grapples',
    alt: 'Pallet Fork Grapples',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/rock-buckets/',
    imageId: 'rockBucketsImage',
    name: 'Rock Buckets',
    alt: 'Rock Buckets',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/root-grapples/',
    imageId: 'rootGrapplesImage',
    name: 'Root Grapples',
    alt: 'Root Grapples',
  },
  {
    link: '/implements/utility-tractors/loader-attachments/silage-defacers/',
    imageId: 'silageDefacersImage',
    name: 'Silage Defacers',
    alt: 'Silage Defacers',
  },
]

const LoaderAttachmentsPage = ({ data }) => {
  const { heroImage } = data
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>Utility Tractor Implements - Loader Attachments | Hutson Inc</title>
        <meta
          name='description'
          content='Loaders were made for more than just hauling dirt. Check out our full selection of Frontier loader attachments for John Deere utility tractors at Hutson.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Implements',
                'item': 'https://www.hutsoninc.com/implements/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Utility Tractors',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/',
              },
              {
                '@type': 'ListItem',
                'position': 4,
                'name': 'Loader Attachments',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/loader-attachments/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Loader Attachments' />

      <Content>
        <Floater items={gridItems} />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    heroImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments-header.jpg" }
    ) {
      ...FullWidthImage
    }
    fourInOneBucketsImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/4-in-1-buckets.jpg" }
    ) {
      ...FloatingGridImage
    }
    baleForksImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/bale-forks.jpg" }
    ) {
      ...FloatingGridImage
    }
    baleHuggersImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/bale-huggers.jpg" }
    ) {
      ...FloatingGridImage
    }
    baleSpearsImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/bale-spears.jpg" }
    ) {
      ...FloatingGridImage
    }
    debrisBlowersImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/debris-blowers.jpg" }
    ) {
      ...FloatingGridImage
    }
    debrisGrapplesImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/debris-grapples.jpg" }
    ) {
      ...FloatingGridImage
    }
    freeStallScrapersImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/free-stall-scrapers.jpg" }
    ) {
      ...FloatingGridImage
    }
    hydraulicPowerPacksImage: file(
      relativePath: {
        eq: "implements/utility-tractors/loader-attachments/hydraulic-power-packs.jpg"
      }
    ) {
      ...FloatingGridImage
    }
    largeBaleSqueezersImage: file(
      relativePath: {
        eq: "implements/utility-tractors/loader-attachments/large-square-bale-squeezers.jpg"
      }
    ) {
      ...FloatingGridImage
    }
    manureForksImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/manure-forks.jpg" }
    ) {
      ...FloatingGridImage
    }
    mechanicalGrapplesImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/mechanical-grapples.jpg" }
    ) {
      ...FloatingGridImage
    }
    manureForksWithGrappleImage: file(
      relativePath: {
        eq: "implements/utility-tractors/loader-attachments/manure-forks-with-grapple.jpg"
      }
    ) {
      ...FloatingGridImage
    }
    palletForksImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/pallet-forks.jpg" }
    ) {
      ...FloatingGridImage
    }
    palletForkGrapplesImage: file(
      relativePath: {
        eq: "implements/utility-tractors/loader-attachments/pallet-fork-grapples.jpg"
      }
    ) {
      ...FloatingGridImage
    }
    rockBucketsImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/rock-buckets.jpg" }
    ) {
      ...FloatingGridImage
    }
    rootGrapplesImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/root-grapples.jpg" }
    ) {
      ...FloatingGridImage
    }
    silageDefacersImage: file(
      relativePath: { eq: "implements/utility-tractors/loader-attachments/silage-defacers.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default LoaderAttachmentsPage
